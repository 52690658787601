import React, { useCallback, useMemo, useState } from "react";
import StationForm from "./station-form";
import useForm from "hooks/useForm.v2";
import { stationFormInitialState } from "./station-form.state";
import { useApi, useModal } from "hooks";
import { createStation, getAccountStationByCode, getStationByCode } from "apis/account-stations";
import { FormMode, Path, StationFrom } from "enums";
import { mapFormToRequest } from "./station.mapper";
import PlatformType from "enums/platform-type";
import OnboardStatus from "enums/onboard-status";
import { Button } from "components/commons";
import locale from "localization";
import OnboardStationModal from "./onboard-station-modal";
import { ConfirmModal, SuccessModal } from "components/modals";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { SuccessFormSubmit } from "images";
import StationType from "enums/station-type";

const AddStation = () => {
  const history = useHistory();
  const [submittedStationCode, setSubmittedStationCode] = useState("");
  const [validStationCode, setValidStationCode] = useState("");
  const [, setIsland] = useState("");
  const form = useForm(stationFormInitialState);
  const { modifyForm, fields } = form;
  const { stationCode, fetchStationFrom } = fields;
  const onboardModal = useModal();
  const successModal = useModal();
  const confirmModal = useModal();
  const getAccountStation = useApi({
    api: getAccountStationByCode,
    handleOwnError: true,
  });
  const stationCodeRequest = useApi({
    api: getStationByCode,
    handleOwnError: true,
  });

  const createRequest = useApi({
    api: createStation,
  });

  const isPriceTool = fetchStationFrom?.value === StationFrom.PRICETOOL;

  const isValidStationCode =
    submittedStationCode &&
    validStationCode &&
    submittedStationCode === validStationCode &&
    stationCode.value === validStationCode;

  const setFieldsVisibility = (isVisible = true) => {
    let fieldsVisible = {};
    Object.entries(stationFormInitialState.form).forEach(([key, field]) => {
      fieldsVisible = {
        ...fieldsVisible,
        [key]: {
          ...field,
          visible: isVisible,
        },
      };
    });

    return fieldsVisible;
  };

  const onSubmit = useCallback(async () => {
    if (validStationCode) {
      await form.onSubmit(async (params) => {
        const mappedParams = {
          ...mapFormToRequest({
            ...params,
            productAvailability: isPriceTool ? undefined : params?.productAvailability,
          }),
        };
        if (params.initialOnboarding === OnboardStatus.OnBoardNow) {
          onboardModal.show({
            platformType: mappedParams.platformType,
            submit: async () => {
              await createRequest.request(mappedParams);
              onboardModal.close();
              successModal.show();
            },
          });
        } else {
          confirmModal.show({
            title: locale.addStationAndOnBoardLater,
            content: locale.youAreNowAddingThisStation,
            primary: {
              text: locale.yesOnboardLater,
              onClick: async () => {
                try {
                  await createRequest.request(mappedParams);
                  confirmModal.close();
                  successModal.show({
                    content: locale.stationHasBeenAdded,
                  });
                } catch (e) {
                  const { data = {} } = e || {};
                  const { errorCode, message } = data;
                  if (message?.toLowerCase()?.includes("station")) {
                    stationCode.onChange(stationCode.name, {
                      errorCode: isPriceTool && errorCode === "S1000" ? "S1009" : errorCode,
                      value: stationCode.value,
                    });
                  }
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  confirmModal.close();
                }
              },
            },
            secondary: {
              text: locale.cancel,
              onClick: async () => {
                confirmModal.close();
              },
            },
          });
        }
      });
    } else {
      // let settlementDetailsFields = {
      //   bankAccountName: {
      //     label: locale.bankAccountName,
      //     visible: true,
      //     validations: {},
      //   },
      //   bankAccountNumber: {
      //     label: locale.bankAccountNumber,
      //     visible: true,
      //     type: "input",
      //     validations: {
      //       isNumeric: true,
      //     },
      //   },
      //   nameOfBank: {
      //     label: locale.nameOfBank,
      //     visible: true,
      //     validations: {},
      //   },
      //   settlementEmail: {
      //     label: locale.settlementEmail,
      //     visible: true,
      //     validations: {
      //       isEmail: true,
      //     },
      //     placeholder: "business@email.com",
      //   },
      // };

      if (!isPriceTool) {
        const res = await getAccountStation
          ?.request({
            stationCode: stationCode.value,
          })
          .catch((error) => error);

        if (res?.stationCode && [StationType.XDODO, StationType.XWIDO].includes(res?.stationType)) {
          return modifyForm({
            ...fields,
            [stationCode.name]: {
              errorCode: "S1001",
              value: stationCode.value,
            },
          });
        }
      }

      try {
        const res = await stationCodeRequest.request({
          stationCode: stationCode.value,
        });

        const {
          businessName,
          stationType,
          availableProducts = [],
          depot = {},
          companyGroup,
          // island,
        } = res || {};

        if (!isPriceTool) {
          return modifyForm({
            ...fields,
            [stationCode.name]: {
              errorCode: "S1010",
              value: stationCode.value,
            },
          });
        }

        setValidStationCode(stationCode.value);
        const { depotCode, depotLocation } = depot;
        let fieldsVisible = setFieldsVisibility(true);

        let productAvailability = {};
        availableProducts?.forEach(
          (val) => (productAvailability = { ...productAvailability, [val]: true })
        );

        // settlementDetailsFields = [StationType.CODO, StationType.DODO].includes(
        //   stationType?.toUpperCase()
        // )
        //   ? settlementDetailsFields
        //   : {};

        modifyForm({
          ...fieldsVisible,
          // island: {
          //   value: island,
          //   visible: true,
          // },
          // settlement fields
          // ...settlementDetailsFields,
          businessName: {
            value: businessName,
            visible: true,
          },
          stationType: {
            value: stationType,
            visible: true,
          },
          depotId: {
            value: depotCode,
            visible: true,
          },
          depotLocation: {
            value: depotLocation,
            visible: true,
          },
          companyGroup: {
            value: companyGroup,
            visible: true,
          },
          productAvailability: {
            value: productAvailability,
            visible: true,
          },
          [stationCode.name]: {
            value: stationCode.value,
          },
          fetchStationFrom: {
            value: fetchStationFrom?.value,
          },
        });
      } catch (e) {
        const { data = {} } = e || {};
        const { errorCode } = data;

        if (!isPriceTool && (errorCode === "S1001" || errorCode === "S1015")) {
          return modifyForm({
            ...fields,
            [stationCode.name]: {
              errorCode: "S1010",
              value: stationCode.value,
            },
          });
        }

        // Not Found
        if (!isPriceTool && errorCode === "S1000") {
          setValidStationCode(stationCode.value);
          const visibleFields = setFieldsVisibility(true);
          return modifyForm({
            ...visibleFields,
            // ...settlementDetailsFields,
            [stationCode.name]: {
              value: stationCode.value,
            },
            fetchStationFrom: {
              value: fetchStationFrom?.value,
            },
          });
        }

        const errorMessage = ["S1000", "S1001", "S1002", "S1007", "S1008", "S1015"];

        if (errorMessage.includes(errorCode?.toString())) {
          modifyForm({
            ...fields,
            [stationCode.name]: {
              errorCode: errorCode === "S1000" ? "S1009" : errorCode,
              value: stationCode.value,
            },
          });
        } else {
          e.showError();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    validStationCode,
    form,
    isPriceTool,
    stationCode,
    stationCodeRequest,
    fetchStationFrom?.value,
    fields,
  ]);

  const isFormSubmittable = useMemo(() => {
    if (isValidStationCode) {
      return form.isFormSubmittable;
    }
    return (
      (stationCode && !stationCode?.error && stationCode?.value?.length >= 4) ||
      stationCode?.value !== submittedStationCode
    );
  }, [isValidStationCode, stationCode, form.isFormSubmittable, submittedStationCode]);

  const onChangeOnBoardingValue = useCallback(
    ({ initialOnboardingValue, platformTypeValue }) => {
      const { fields = {} } = form;
      const {
        mobileNumberReceiving,
        bankAccountName,
        bankAccountNumber,
        nameOfBank,
        settlementEmail,
        diesel,
        gas91,
        gas95,
        gas97,
        plbMaxDiscount,
        stationType,
      } = fields;

      const showPLCInformation = [PlatformType.PLC, PlatformType.PLCandPLB].includes(
        platformTypeValue.value
      );
      const showSettlementInfo =
        initialOnboardingValue.value === OnboardStatus.OnBoardNow &&
        ["CODO", "DODO"].includes(stationType.value);

      const showMaxDiscounts =
        [PlatformType.PLB, PlatformType.PLCandPLB].includes(platformTypeValue.value) &&
        ["WIDOX", "DODOX"].includes(stationType.value);

      modifyForm({
        ...fields,
        platformType: platformTypeValue,
        initialOnboarding: initialOnboardingValue,
        mobileNumberReceiving: {
          value: showPLCInformation ? mobileNumberReceiving.value : "",
          validations: {
            ...mobileNumberReceiving.validations,
            isRequired: showPLCInformation,
          },
          visible: showPLCInformation,
        },
        bankAccountName: {
          value: showSettlementInfo ? bankAccountName.value : "",
          validations: {
            ...bankAccountName.validations,
            isRequired: showSettlementInfo,
          },
          visible: showSettlementInfo,
        },
        nameOfBank: {
          value: showSettlementInfo ? nameOfBank.value : "",
          validations: {
            ...nameOfBank.validations,
            isRequired: showSettlementInfo,
          },
          visible: showSettlementInfo,
        },
        bankAccountNumber: {
          value: showSettlementInfo ? bankAccountNumber.value : "",
          validations: {
            ...bankAccountNumber.validations,
            isRequired: showSettlementInfo,
          },
          visible: showSettlementInfo,
        },
        settlementEmail: {
          value: showSettlementInfo ? settlementEmail.value : "",
          validations: {
            ...settlementEmail.validations,
            isRequired: showSettlementInfo,
          },
          visible: showSettlementInfo,
        },
        plbMaxDiscount: {
          value: showMaxDiscounts ? plbMaxDiscount.value : "",
          visible: showMaxDiscounts,
        },
        diesel: {
          value: showMaxDiscounts ? diesel.value : "",
          validations: {
            ...diesel.validations,
            isRequired: showMaxDiscounts,
          },
          visible: showMaxDiscounts,
        },
        gas91: {
          value: showMaxDiscounts ? gas91.value : "",
          validations: {
            ...gas91.validations,
            isRequired: showMaxDiscounts,
          },
          visible: showMaxDiscounts,
        },
        gas95: {
          value: showMaxDiscounts ? gas95.value : "",
          validations: {
            ...gas95.validations,
            isRequired: showMaxDiscounts,
          },
          visible: showMaxDiscounts,
        },
        gas97: {
          value: showMaxDiscounts ? gas97.value : "",
          validations: {
            ...gas97.validations,
            isRequired: showMaxDiscounts,
          },
          visible: showMaxDiscounts,
        },
      });
    },
    [form, modifyForm]
  );

  const formFields = useMemo(() => {
    const { fields, ...value } = form;
    const { stationCode, fetchStationFrom } = fields;
    if (isValidStationCode && fetchStationFrom.value === StationFrom.PRICETOOL) {
      const obj = {};
      Object.keys(fields).forEach((key) => {
        const fieldKeys = Object.keys(fields[key]);
        obj[key] = {
          ...fields[key],
          visible: fieldKeys.includes("visible") ? fields[key].visible : true,
        };
      });
      const { platformType, initialOnboarding, ...fieldObj } = obj;
      return {
        ...value,
        fields: {
          ...fieldObj,
          initialOnboarding: {
            ...initialOnboarding,
            validations: {
              isRequired: false,
            },
            value: OnboardStatus.OnBoardLater,
            onChange: (name, value) => {
              onChangeOnBoardingValue({
                initialOnboardingValue: value,
                platformTypeValue: {
                  value: "",
                  validations: {
                    isRequired: value.value === OnboardStatus.OnBoardNow,
                  },
                },
              });
            },
          },
          platformType: {
            ...platformType,
            validations: {
              isRequired: false,
            },
            onChange: (name, value) => {
              onChangeOnBoardingValue({
                initialOnboardingValue: initialOnboarding,
                platformTypeValue: value,
              });
            },
          },
        },
      };
    }

    return {
      ...value,
      fields: {
        ...fields,
        fetchStationFrom: {
          ...fetchStationFrom,
          onChange: (name, field) => {
            fetchStationFrom.onChange(name, {
              ...field,
              errorCode: null,
            });
          },
        },
        stationCode: {
          ...stationCode,
          onChange: (name, field) => {
            stationCode.onChange(name, {
              ...field,
              errorCode: null,
            });
          },
        },
      },
    };
  }, [form, isValidStationCode, onChangeOnBoardingValue]);

  return (
    <div>
      <StationForm
        isFormSubmittable={isFormSubmittable}
        form={formFields}
        modifyForm={form.modifyForm}
        onSubmit={onSubmit}
        formMode={FormMode.Add}
        setValidStationCode={setValidStationCode}
        initializeForm={form.initializeForm}
        setFieldsVisibility={setFieldsVisibility}
        overrideForm={form.overrideForm}
      />
      <div
        style={{
          marginTop: "50px",
        }}
      >
        <Button
          loading={
            stationCodeRequest.loading || createRequest.loading || getAccountStation?.loading
          }
          onClick={onSubmit}
          primary
          disabled={
            !isFormSubmittable ||
            stationCodeRequest.loading ||
            createRequest.loading ||
            getAccountStation?.loading
          }
        >
          {locale.continue}
        </Button>
      </div>
      <ConfirmModal {...confirmModal} loading={createRequest.loading} />
      <OnboardStationModal {...onboardModal} loading={createRequest.loading} />
      <SuccessModal
        image={SuccessFormSubmit}
        title={locale.exclamatedSuccess}
        content={locale.stationHasOnboarded}
        {...successModal}
        close={() => {
          history.push(Path.Station);
          successModal.close();
        }}
        secondary={{
          text: locale.goToStationAccountList,
          onClick: () => {
            history.push(Path.Station);
            successModal.close();
          },
        }}
        primary={{
          text: locale.addAnotherStation,
          onClick: () => {
            form.initializeForm({}, true);
            setSubmittedStationCode("");
            setValidStationCode("");
            setIsland("");
            successModal.close();
          },
        }}
      />
    </div>
  );
};

export default AddStation;
