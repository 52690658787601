import { PopOverMenu, PopOver, Text } from "components/commons";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { locale } from "localization/en";
import { DateTime, Path } from "enums";
import KYCSubmissionStatus from "enums/kyc-submission-status";
import { prettifyKYCSubmissionStatus } from "utils/pretty.utils";
import LabelAndPill from "components/commons/label-and-pill/label-and-pill";
import { formatDate } from "utils";

export const mapDataToList = ({ kyc = {}, history }) => {
  const {
    firstName = "",
    lastName = "",
    kycId,
    driversLicense,
    mobileNumber,
    company,
    createdAt,
    verificationStatus: status,
    riderId,
    updatedAt,
    remarks,
    companyName,
  } = kyc;

  const options = [
    {
      removable: true,
      content: locale.viewDetailsKYC,
      onClick: (event) => {
        if (event?.ctrlKey || event?.metaKey) {
          window.open(Path.ViewKYCSubmissionsId(kycId), "_blank");
        } else {
          history.push(Path.ViewKYCSubmissionsId(kycId));
        }
      },
    },
  ];

  return {
    kycId,
    driverInfo: driversLicense || "--",
    mobileNumber,
    name: `${firstName} ${lastName}`.trim(),
    company: (
      <>
        {companyName ? (
          <Text>
            {company} - {companyName}
          </Text>
        ) : (
          <Text>{company}</Text>
        )}
      </>
    ),
    riderId: riderId || "--",
    dateApplied: (
      <div className="max-90">{createdAt ? formatDate(createdAt, DateTime.R) : "--"}</div>
    ),
    statusAndDateUpdated: (
      <div>
        <LabelAndPill
          label={updatedAt ? formatDate(updatedAt, DateTime.R) : "--"}
          pillProps={{
            sky: status === KYCSubmissionStatus.Pending,
            grass: status === KYCSubmissionStatus.Approved,
            deepRed:
              status === KYCSubmissionStatus.Rejected || status === KYCSubmissionStatus.Expired,
            cement: status === KYCSubmissionStatus.Deleted,
          }}
          pillLabel={prettifyKYCSubmissionStatus(status)}
        />
      </div>
    ),
    remarks:
      remarks && status === KYCSubmissionStatus.Rejected ? (
        <PopOver
          content={
            <div
              style={{
                maxHeight: "160px",
                overflowX: "auto",
              }}
            >
              {remarks}
            </div>
          }
        >
          <div className="link">View</div>
        </PopOver>
      ) : (
        ""
      ),
    action: (
      <PopOverMenu options={options}>
        <MoreVertIcon />
      </PopOverMenu>
    ),
  };
};

export const mapFilterToRequest = (filterState) => {
  const { verificationStatuses } = filterState;

  return {
    ...filterState,
    verificationStatuses: verificationStatuses.join(","),
  };
};
