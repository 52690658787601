import locale from "localization";
import moment from "moment";
// import { isNumber } from "utils/text.utils";

export const stationFormInitialState = {
  form: {
    fetchStationFrom: {
      label: locale.fetchStationFrom,
      visible: true,
      value: "",
      validations: {
        isRequired: true,
      },
      placeholder: "Select Option",
    },
    stationCode: {
      label: locale.stationCode,
      visible: true,
      maxLength: 10,
      type: "input",
      validations: {
        isRequired: true,
        isAlphaNumeric: true,
        custom: [
          (value, field) => {
            const { errorCode } = field;
            const errorMessage = {
              S1001: locale.sorryStationAlreadyInDatabase,
              S1000: locale.sorryThisStationCodeDoesNotExist,
              S1002: locale.sorryStationHasInvalidType,
              S1007: locale.stationHasNoDepot,
              S1008: "Sorry, the station has an invalid station type.",
              S1009: locale.priceToolNotFound,
              S1015: locale.sorryThisStationIsAlreadyInactive,
              S1010: locale.sorryThisStationAlreadyHasAPriceToolEntry,
            };
            return {
              error: Boolean(errorCode),
              message: errorMessage[errorCode],
            };
          },
        ],
      },
    },
    stationName: {
      label: locale.stationName,
      validations: {
        isRequired: true,
      },
      placeholder: "e.g. SEAOIL CALUMPIT - Bulacan",
    },
    businessName: {
      name: "businessName",
      label: locale.businessName,
    },
    companyGroup: {
      label: locale.companyGroup,
    },
    stationType: {
      name: "stationType",
      label: locale.stationType,
    },
    depotLocation: {
      label: locale.depotLocation,
    },
    depotId: {
      label: locale.depotId,
    },
    platformType: {
      value: "",
      validations: {
        isRequired: false,
      },
    },
    productAvailability: {
      label: locale.productAvailability,
      validations: {
        isRequired: true,
      },
    },
    fullAddress: {
      label: locale.fullAddress,
      validations: {
        isRequired: true,
      },
      maxLength: 200,
    },
    // island: {
    //   label: locale.island,
    // },
    province: {
      label: locale.province,
      validations: {
        isRequired: true,
      },
    },
    city: {
      label: `${locale.city}/${locale.municipality}`,
      validations: {
        isRequired: true,
      },
    },
    openingTime: {
      label: locale.stationOpeningTime,
      value: moment("00:00:00", "HH:mm:ss").format(),
      validations: {
        isRequired: true,
        isDateTime: true,
      },
    },
    closingTime: {
      label: locale.stationClosingTime,
      value: moment("23:59:59", "HH:mm:ss").format(),
      validations: {
        isRequired: true,
        isDateTime: true,
      },
    },
    latitude: {
      label: locale.latitude,
      validations: {
        isRequired: true,
        isNumber: true,
      },
      type: "input",
      maxLength: 15,
      placeholder: "e.g.14.34098",
    },
    longitude: {
      label: locale.longitude,
      validations: {
        isRequired: true,
        isNumber: true,
      },
      maxLength: 15,
      type: "input",
      placeholder: "e.g.120.20032",
    },
    emailAddress: {
      label: locale.stationEmailAddress,
      validations: {
        isRequired: true,
        isEmail: true,
      },
      placeholder: "e.g. station@email.com",
    },
    rtmName: {
      label: locale.rtmName,
      validations: {
        isRequired: true,
        isAlphaNumericName: true,
      },
      type: "input",
    },
    rtmEmailAddress: {
      label: locale.rtmEmailAddress,
      validations: {
        isRequired: true,
        isEmail: true,
      },
      placeholder: "e.g. rtm@email.com",
    },
    initialOnboarding: {
      label: locale.initialOnboarding,
    },
    // mobileNumberReceiving: {
    //   label: locale.mobileNumberReceiving,
    //   visible: false,
    //   maxLength: 10,
    //   type: "input",
    //   validations: {
    //     isNumeric: true,
    //     custom: [
    //       (value) => {
    //         const error = value
    //           ? value[0] !== "9" || value.length !== 10 || !isNumber(value)
    //           : false;
    //         return {
    //           error,
    //           message: error ? "Invalid mobile number" : "",
    //         };
    //       },
    //     ],
    //   },
    // },
    // plbMaxDiscount: {
    //   label: locale.plbMaxDiscount,
    //   visible: false,
    // },
    // bankAccountName: {
    //   label: locale.bankAccountName,
    //   visible: false,
    //   validations: {},
    // },
    // bankAccountNumber: {
    //   label: locale.bankAccountNumber,
    //   visible: false,
    //   type: "input",
    //   validations: {
    //     isNumeric: true,
    //   },
    // },
    // nameOfBank: {
    //   label: locale.nameOfBank,
    //   visible: false,
    //   validations: {},
    // },
    // settlementEmail: {
    //   label: locale.settlementEmail,
    //   visible: false,
    //   validations: {
    //     isEmail: true,
    //   },
    //   placeholder: "business@email.com",
    // },
    // diesel: {
    //   label: "Diesel",
    //   validations: {
    //     isAmount: true,
    //   },
    // },
    // gas91: {
    //   label: "Gas 91",
    //   validations: {
    //     isAmount: true,
    //   },
    // },
    // gas95: {
    //   label: "Gas 95",
    //   validations: {
    //     isAmount: true,
    //   },
    // },
    // gas97: {
    //   label: "Gas 97",
    //   validations: {
    //     isAmount: true,
    //   },
    // },
  },
};
