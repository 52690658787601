import React from "react";
// import classNames from "classnames";
import locale from "localization";
import { BackLink, Field, Intro, Text, Title } from "components/commons";
import { Path } from "enums";
import { Skeleton } from "@mui/material";

const StationSkeleton = ({ title = locale.addStation, fields = {}, loading, intro }) => {
  const {
    fetchStationFrom = {},
    stationCode = {},
    stationName = {},
    businessName = {},
    companyGroup = {},
    stationType = {},
    depotLocation = {},
    depotId = {},
    productAvailability = {},
    fullAddress = {},
    province = {},
    // island,
    city = {},
    openingTime = {},
    closingTime = {},
    latitude = {},
    longitude = {},
    emailAddress = {},
    rtmName = {},
    rtmEmailAddress = {},
    initialOnboarding = {},
    // mobileNumberReceiving = {},
    // plbMaxDiscount = {},
    bankAccountName = {},
    bankAccountNumber = {},
    nameOfBank = {},
    settlementEmail = {},
  } = fields || {};
  return (
    <div>
      <BackLink text={locale.stationAccountList} path={Path.Station} />
      <Intro title={title} {...intro} />
      {[
        {
          title: locale.basicInformation,
          fields: [
            fetchStationFrom,
            stationCode,
            stationName,
            businessName,
            stationType,
            companyGroup,
            depotLocation,
            depotId,
            productAvailability,
            fullAddress,
            province,
            // island,
            city,
            openingTime,
            closingTime,
            latitude,
            longitude,
            emailAddress,
            rtmName,
            rtmEmailAddress,
            initialOnboarding,
          ],
        },
        // {
        //   title: locale.plcInformation,
        //   fields: [mobileNumberReceiving],
        // },
        // {
        //   title: locale.plbPricingInformation,
        //   fields: [plbMaxDiscount],
        // },
        {
          title: locale.settlementInformation,
          fields: [bankAccountName, bankAccountNumber, nameOfBank, settlementEmail],
        },
      ]
        .filter((group) => {
          const hasVisible = group.fields.find((item) => item.visible);
          return hasVisible;
        })
        .map((group, groupIndex) => {
          return (
            <div
              key={groupIndex}
              style={{
                maxWidth: "650px",
              }}
            >
              <Title xsmall>{group.title}</Title>
              <div>
                {group.fields
                  .filter((field) => field.visible)
                  .map((field, fieldIndex) => {
                    return (
                      <Field
                        childrenStyle={{
                          minWidth: "400px",
                          maxWidth: "400px",
                        }}
                        key={fieldIndex}
                        labelPosition="left"
                        label={field.label}
                        error={field.error}
                        messages={field.messages}
                        {...field}
                      >
                        {loading ? (
                          <Skeleton />
                        ) : (
                          field.content || (
                            <Text strong>
                              {typeof field.value === "string" && field?.value?.length > 0
                                ? field.value
                                : "n/a"}
                            </Text>
                          )
                        )}
                      </Field>
                    );
                  })}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default StationSkeleton;
