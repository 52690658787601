import {
  Field,
  Text,
  TextField,
  TimePicker,
  Autocomplete,
  DropdownPaginated,
} from "components/commons";
import Products from "components/commons/product/products";
import { InputAmount } from "components/field";
import SelectCities from "components/field/dropdown/select-cities";
import SelectProvince from "components/field/dropdown/select-province";
import locale from "localization";
import React from "react";
import StationFrom from "enums/station-from";
import { prettifyProduct, prettifyStationFrom } from "utils/pretty.utils";

import StationSkeleton from "./station-skeleton";
import StationType from "enums/station-type";
import { FormMode, Products as ProductsEnum } from "enums";
import { useApi } from "hooks";
import { getStationByCode } from "apis/account-stations";

const StationForm = ({
  loading,
  title,
  form = {},
  intro,
  modifyForm,
  formMode,
  setValidStationCode,
  initializeForm,
  setFieldsVisibility,
  overrideForm,
}) => {
  const { fields } = form;
  const {
    fetchStationFrom = {},
    stationCode = {},
    stationName = {},
    businessName = {},
    companyGroup = {},
    stationType = {},
    depotLocation = {},
    depotId = {},
    productAvailability = {},
    fullAddress = {},
    province = {},
    city = {},
    openingTime = {},
    closingTime = {},
    latitude = {},
    longitude = {},
    emailAddress = {},
    rtmName = {},
    rtmEmailAddress = {},
    mobileNumberReceiving = {},
    plbMaxDiscount = {},
    bankAccountName = {},
    bankAccountNumber = {},
    nameOfBank = {},
    settlementEmail = {},
    diesel = {},
    gas91 = {},
    gas95 = {},
    gas97 = {},
    // island = {},
  } = fields || {};

  const stationCodeRequest = useApi({
    api: getStationByCode,
    handleOwnError: true,
  });

  const isPriceTool =
    fetchStationFrom?.value === StationFrom.PRICETOOL || fetchStationFrom?.value === "";
  const isEditable = formMode !== FormMode.View && !isPriceTool;
  const stationTypeOptions = (
    fetchStationFrom?.value === StationFrom.PRICETOOL
      ? [
          StationType.COCO,
          StationType.COXO,
          StationType.WILCO,
          StationType.WIBCO,
          StationType.CODO,
          StationType.DODO,
          StationType.WIDOX,
          StationType.DODO,
          StationType.DODOX,
        ]
      : [StationType.XWIDO, StationType.XDODO]
  ).map((val) => ({
    label: val,
    value: val,
  }));

  const productsOption = [
    ProductsEnum.Diesel,
    ProductsEnum.Gas91,
    ProductsEnum.Gas95,
    ProductsEnum.Gas97,
    ProductsEnum.Lubes,
  ].map((val) => ({
    label: prettifyProduct(val),
    customLabel: <Products {...{ [val]: val }} />,
    value: val,
  }));

  const handleOnChangeFetchStationFrom = async (name, { value }) => {
    if (value === fetchStationFrom?.value) {
      return modifyForm({ fetchStationFrom: { value } });
    }

    const newStationTypeOptions =
      value === StationFrom.PRICETOOL
        ? [
            StationType.COCO,
            StationType.COXO,
            StationType.WILCO,
            StationType.WIBCO,
            StationType.CODO,
            StationType.DODO,
            StationType.WIDOX,
            StationType.DODO,
            StationType.DODOX,
          ]
        : [StationType.XWIDO, StationType.XDODO];

    let fieldsVisible = setFieldsVisibility(false);
    const updateFields = {
      ...fieldsVisible,
      stationType: {
        ...fieldsVisible?.stationType,
        value: newStationTypeOptions.includes(stationType.value) ? stationType.value : "",
      },
      stationCode: {
        ...fieldsVisible.stationCode,
        visible: true,
        errorCode: null,
        value: "",
      },
      productAvailability: {
        ...fieldsVisible.productAvailability,
        value: [],
      },
      fetchStationFrom: {
        value: value,
      },
    };

    initializeForm?.(updateFields, true);
  };

  return (
    <div>
      <StationSkeleton
        intro={intro}
        title={title}
        loading={loading}
        fields={{
          fetchStationFrom: {
            ...fetchStationFrom,
            value:
              formMode === FormMode.Add
                ? fetchStationFrom?.value
                : prettifyStationFrom(fetchStationFrom?.value),
            content:
              fetchStationFrom.content ||
              (formMode === FormMode.Add && (
                <Autocomplete
                  {...fetchStationFrom}
                  placeholder={"Select Option"}
                  options={[StationFrom.PRICETOOL, StationFrom.NONPRICETOOL].map((val) => ({
                    label: prettifyStationFrom(val),
                    value: val,
                  }))}
                  onChange={handleOnChangeFetchStationFrom}
                />
              )),
          },
          stationCode: {
            ...stationCode,
            content: stationCode.content || (
              <TextField
                {...stationCode}
                loading={stationCodeRequest?.loading}
                onChange={(name, { value }) => {
                  setValidStationCode(false);
                  const fieldsReset = setFieldsVisibility(false);
                  overrideForm?.({
                    ...fieldsReset,
                    fetchStationFrom: {
                      ...fetchStationFrom,
                      visible: true,
                    },
                    stationCode: {
                      ...stationCode,
                      visible: true,
                      value,
                      error: false,
                      errorCode: null,
                    },
                  });
                }}
              />
            ),
          },
          stationName: {
            ...stationName,
            content: stationName.content || (
              <div>
                <TextField {...stationName} disabled={loading} />
                <Text
                  subtitle
                  style={{
                    marginTop: "4px",
                  }}
                >
                  {locale.formatStationName}
                </Text>
              </div>
            ),
          },
          businessName: {
            ...businessName,
            content: isEditable && <TextField {...businessName} />,
          },
          stationType: {
            ...stationType,
            content: isEditable && (
              <Autocomplete
                {...stationType}
                placeholder={locale.searchOrSelect}
                options={stationTypeOptions}
              />
            ),
          },
          companyGroup: {
            ...companyGroup,
            content: <Text strong>{companyGroup?.value?.toUpperCase() || "n/a"}</Text>,
          },
          depotLocation: { ...depotLocation },
          depotId: { ...depotId },
          productAvailability: {
            ...productAvailability,
            content: isEditable ? (
              <DropdownPaginated
                {...fields?.productAvailability}
                label=""
                placeholder={locale.searchOrSelect}
                value={
                  fields?.productAvailability?.value?.length > 0
                    ? fields?.productAvailability?.value
                    : []
                }
                onChange={(name, props) => {
                  modifyForm({
                    [name]: {
                      ...props,
                      value: props?.isAll
                        ? [
                            ProductsEnum.Diesel,
                            ProductsEnum.Gas91,
                            ProductsEnum.Gas95,
                            ProductsEnum.Gas97,
                            ProductsEnum.Lubes,
                          ]
                        : props.value,
                    },
                  });
                }}
                options={productsOption}
                isMultiple
                total={productsOption.length}
                selectAllLabel={locale.all}
                request={() => productsOption}
                formatRequest={(data) => data}
              />
            ) : (
              <Products horizontal {...productAvailability.value} textClassName="bold" />
            ),
          },
          fullAddress: {
            ...fullAddress,
            content: fullAddress.content || <TextField {...fullAddress} disabled={loading} />,
          },
          province: {
            ...province,
            content: province.content || (
              <SelectProvince
                {...province}
                disabled={loading}
                onChange={(name, value) => {
                  if (!value.isDirty) {
                    form.modifyForm({
                      province: value,
                    });
                  } else {
                    form.modifyForm({
                      province: value,
                      city: {
                        value: "",
                      },
                    });
                  }
                }}
              />
            ),
          },
          // island: {
          //   ...island,
          //   content: <Text strong>{capitalize(island?.value || "-")}</Text>,
          // },
          city: {
            ...city,
            content: city.content || (
              <SelectCities {...city} province={province.key} disabled={loading} />
            ),
          },
          openingTime: {
            ...openingTime,
            content: openingTime.content || (
              <div
                style={{
                  width: "160px",
                }}
              >
                <TimePicker {...openingTime} />
              </div>
            ),
          },
          closingTime: {
            ...closingTime,
            content: closingTime.content || (
              <div
                style={{
                  width: "160px",
                }}
              >
                <TimePicker {...closingTime} />
              </div>
            ),
          },
          latitude: {
            ...latitude,
            content: latitude.content || (
              <div>
                <TextField {...latitude} disabled={loading} />
                <Text
                  subtitle
                  style={{
                    marginTop: "4px",
                  }}
                >
                  {locale.populate(locale.pleaseInputAtleast5Decimal, ["14.34098"])}
                </Text>
              </div>
            ),
          },
          longitude: {
            ...longitude,
            content: longitude.content || (
              <div>
                <TextField {...longitude} disabled={loading} />{" "}
                <Text
                  subtitle
                  style={{
                    marginTop: "4px",
                  }}
                >
                  {locale.populate(locale.pleaseInputAtleast5Decimal, ["120.20032"])}
                </Text>
              </div>
            ),
          },
          emailAddress: {
            ...emailAddress,
            content: emailAddress.content || <TextField {...emailAddress} disabled={loading} />,
          },
          rtmName: {
            ...rtmName,
            content: rtmName.content || <TextField {...rtmName} disabled={loading} />,
          },
          rtmEmailAddress: {
            ...rtmEmailAddress,
            content: rtmEmailAddress.content || (
              <TextField {...rtmEmailAddress} disabled={loading} />
            ),
          },
          mobileNumberReceiving: {
            ...mobileNumberReceiving,
            content: mobileNumberReceiving.content || (
              <TextField
                {...mobileNumberReceiving}
                disabled={loading}
                prefix={
                  <div>
                    <Text
                      style={{
                        fontSize: "14px",
                      }}
                    >
                      +63
                    </Text>
                  </div>
                }
              />
            ),
          },
          plbMaxDiscount: {
            ...plbMaxDiscount,
            content: plbMaxDiscount.content || (
              <div className="flex items-center">
                {[diesel, gas91, gas95, gas97].map((productField, key) => {
                  return (
                    <div
                      key={key}
                      style={{
                        marginRight: "5px",
                      }}
                    >
                      <Field
                        {...productField}
                        style={{
                          margin: "0px",
                        }}
                      >
                        <div
                          style={{
                            width: "95px",
                          }}
                        >
                          <InputAmount innerLabel {...productField} />
                        </div>
                      </Field>
                    </div>
                  );
                })}
              </div>
            ),
          },
          bankAccountName: {
            ...bankAccountName,
            content: bankAccountName.content || (
              <TextField {...bankAccountName} disabled={loading} />
            ),
          },
          bankAccountNumber: {
            ...bankAccountNumber,
            content: bankAccountNumber.content || (
              <TextField {...bankAccountNumber} disabled={loading} />
            ),
          },
          nameOfBank: {
            ...nameOfBank,
            content: nameOfBank.content || <TextField {...nameOfBank} disabled={loading} />,
          },
          settlementEmail: {
            ...settlementEmail,
            content: settlementEmail.content || (
              <TextField {...settlementEmail} disabled={loading} />
            ),
          },
        }}
      />
    </div>
  );
};

export default StationForm;
