import KYCSubmissionStatus from "enums/kyc-submission-status";

export const initialFilterState = () => ({
  startDate: null,
  endDate: null,
  searchKey: "",
  page: 1,
  perPage: 10,
  verificationStatuses: [
    KYCSubmissionStatus.Approved,
    KYCSubmissionStatus.Pending,
    KYCSubmissionStatus.Rejected,
    KYCSubmissionStatus.Deleted,
    KYCSubmissionStatus.Expired,
  ],
});
