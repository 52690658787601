import { Intro, Text } from "components/commons";
import { UserContext } from "contexts";
import { useExport, useModal } from "hooks";
import React, { useContext } from "react";
import PayHistoryFilter from "./pay-history-filter";
import { payHistoryState } from "./pay-history-filter.state";
import { generateReportAdminLoyalty } from "apis";
import useDataTable from "hooks/useDataTable";
import DataTableV2 from "components/commons/data-table/data-table-v2";
import { mapDataToList, mapFilterToRequest } from "./pay-history.mapper";
import { payHistoryColumns } from "./pay-history-columns";
import { SuccessModal } from "components/modals";
import VoidVIPTransactionHistoryModal from "../transaction-history/void-vip-transaction-history-modal";
import locale from "localization";
import { UserAccess, VipTransactionHistoryExports } from "enums";
import { getTransactionHistoryPayWithPoints } from "apis/vip-transaction-history.api";
const PayHistoryModule = ({ title }) => {
  const { userAccess, sessionId } = useContext(UserContext);

  const getUserAccess = userAccess.find((item) => {
    return item.key === UserAccess.VIPTransactionHistory;
  });

  const newColumns =
    getUserAccess?.fullAccess || getUserAccess?.modifyAccess
      ? payHistoryColumns
      : payHistoryColumns.filter((column) => column.key !== "action");

  const voidModal = useModal();

  const handleOptionClickVoid = (transaction) => {
    voidModal.show({
      title: locale.voidPayWithPointsTitle,
      content: (
        <Text style={{ width: "450px" }}>
          <locale.Populate
            text={locale.voidPayWithPointsContent}
            items={[<b>{transaction?.name}</b>]}
          />
        </Text>
      ),
      secondary: {
        text: "Cancel",
      },
      primary: {
        text: locale.yesProceed,
      },
      pointsHistoryId: transaction.pointsHistoryId,
    });
  };

  const { filter, search, table } = useDataTable({
    api: { api: getTransactionHistoryPayWithPoints },
    filter: { initialState: payHistoryState(), mapper: mapFilterToRequest },
    table: {
      key: "pointsHistory",
      mapper: (pointsHistory) =>
        mapDataToList({ pointsHistory, getUserAccess, onOptionClickVoid: handleOptionClickVoid }),
      columns: newColumns,
    },
  });

  const exports = useExport({
    api: generateReportAdminLoyalty,
    reportType: VipTransactionHistoryExports.VIP_PAY_WITH_POINTS,
    hasModal: true,
    mappedFilterState: {
      ...filter?.mappedFilterState,
      searchKey: search?.searchKey,
      connectionId: sessionId,
    },
  });

  return (
    <div>
      <SuccessModal {...exports?.successModalComponent} />
      <VoidVIPTransactionHistoryModal {...voidModal} onSuccess={() => table.refetch()} />
      <Intro
        title={`${locale.transactionHistory}: ${locale.payWithPoints}`}
        subtitle={locale.payWithPointsHistory}
      />
      <br />
      <PayHistoryFilter {...filter} {...search} {...exports} getUserAccess={getUserAccess} />
      <br />
      <DataTableV2 {...table} paginationV2={true} />
    </div>
  );
};

export default PayHistoryModule;
