import { AppContext } from "contexts";
import { useContext, useState } from "react";

const useApi = ({ api, params = {}, handleOwnError = false, modalError = true, pageError }) => {
  const { errorModal, setPageError, setErrorStatus } = useContext(AppContext);
  const [loading, setLoading] = useState(undefined);
  const [error, setError] = useState(false);
  const [result, setResult] = useState(undefined);

  const showError = (err, retry) => {
    if (pageError) {
      setPageError(true);
      setErrorStatus(err.status);
    } else {
      if (modalError) {
        errorModal.show({
          onClick: async () => {
            errorModal.close();
            await retry();
          },
        });
      }
    }
    throw err;
  };

  const request = async (p, retry = () => {}, options = { useLoader: true }) => {
    const { useLoader = true, cleaned = true } = options || {};
    setError(false);
    if (useLoader) {
      setLoading(true);
    }

    const obj = { ...params, ...p };
    const cleanedObj = {};
    for (const [key, value] of Object.entries(obj)) {
      if (!cleaned) {
        cleanedObj[key] = value;
      } else if (value !== null && value !== "") {
        cleanedObj[key] = value;
      }
    }

    try {
      const res = await api(cleanedObj);
      setResult(res);
      setLoading(false);

      return res;
    } catch (err) {
      console.error("Error", err);

      err.showError = () => showError(err, retry);

      setError(true);
      setErrorStatus(err.status);
      setLoading(false);

      if (err?.error === "timeout") {
        setPageError(true);
        return showError(err);
      }

      if (
        err.status === 403 ||
        err.status === 401 ||
        err?.error === "network" ||
        err?.error === "forbidden"
      ) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        return showError(err);
      }

      if (handleOwnError) {
        if (typeof handleOwnError === "boolean") {
          throw err;
        } else if (typeof handleOwnError === "object") {
          if (handleOwnError.hasOwnProperty(err.error)) {
            throw err;
          } else {
            return showError(err);
          }
        }
      } else {
        return showError(err);
      }
    }
  };

  return { request, loading, result, error };
};

export default useApi;
