import React from "react";
import Product from "./product";
import classNames from "classnames";
import styles from "./product.module.scss";

const Products = ({ diesel, gas91, gas95, gas97, lubes, textClassName, horizontal, style }) => {
  return (
    <div
      className={classNames({
        [`${styles.horizontal}`]: horizontal,
      })}
      style={{
        minWidth: "70px",
        ...style,
      }}
    >
      {diesel && (
        <div className={textClassName}>
          <Product cheddar>Diesel</Product>
        </div>
      )}
      {gas91 && (
        <div className={textClassName}>
          <Product grass>Gas 91</Product>
        </div>
      )}
      {gas95 && (
        <div className={textClassName}>
          <Product salsa>Gas 95</Product>
        </div>
      )}
      {gas97 && (
        <div className={textClassName}>
          <Product deepBlue>Gas 97</Product>
        </div>
      )}
      {lubes && <div className={textClassName}>Lubricants</div>}
    </div>
  );
};

export default Products;
